import React, { FC } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ISymptomsHeaderComponentProps } from './model';
import 'components/SymptomsHeader/SymptomsHeader.scss';

const SymptomsHeader: FC<ISymptomsHeaderComponentProps> = ({
  img,
  imageAlt,
  symptomsList,
  imgShiftedLeft,
  imgRotateShiftedMd,
  imgRotateShiftedSm,
}) => {
  const classes = classNames('symptoms-header__illustration', {
    'symptoms-header--img-shifted-left': imgShiftedLeft === '1',
    'symptoms-header--img-rotated-shifted-md': imgRotateShiftedMd === '1',
    'symptoms-header--img-rotated-shifted-sm': imgRotateShiftedSm === '1',
  });

  const image = img as AppImages.IResponsiveImageData;
  const svg = img as UmbracoTypes.ISvg;

  return (
    <div className="symptoms-header">
      <div className={classes}>
        <div className="symptoms-header__img-holder">
          {image?.gatsbyImage?.childImageSharp ? (
            <GatsbyImage fluid={image.gatsbyImage.childImageSharp.fluid} alt={imageAlt} />
          ) : svg?.svg?.content ? (
            <DangerouslySetInnerHtml html={svg.svg.content} />
          ) : null}
        </div>
      </div>
      <div className="symptoms-header__text">
        <ul className="symptoms-header__list">
          {symptomsList.map(({ properties }, index) => {
            const id = `${properties.text}_${index}`;

            return (
              <li key={id}>
                <span className="symptoms-header__item-text">
                  <DangerouslySetInnerHtml html={properties.text} />
                  {properties.subText ? (
                    <span className="symptoms-header__sup-text">{properties.subText}</span>
                  ) : null}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SymptomsHeader;
